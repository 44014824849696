.toast-body {
  background: var(--theme-ui-colors-box);
}

/* HACK: Fix title color in wallet modal */
.tw-font-medium {
  color: black;
}

/* HACK: Fix title color in wallet modal */
.tw-text-lg {
  color: black;
}

.toast-body {
  background: var(--theme-ui-colors-box);
}
